<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <BuildingBalanceTable :filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import BuildingBalanceTable from '@/app/views/admin/reports/elements/buildingBalanceTable.vue'

export default {
  name: 'BuildingBalance',
  components: { BuildingBalanceTable }
}
</script>
